import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js';
import * as THREE from 'three';
import * as TWEEN from '@tweenjs/tween.js';

import {
	AfterViewInit,
	Component,
	ElementRef,
	ViewChild,
	effect,
	input,
	ChangeDetectorRef,
	output,
	signal
} from '@angular/core';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { DecalGeometry } from 'three/examples/jsm/geometries/DecalGeometry.js';
import { clamp, find, has } from 'lodash';
import { GenderEnum } from '../generated-models/enums';
import { AppLoaderService } from '../layout';
import { BodyAreaType, GetBodyAreaByName, GetBodyAreaBySophosId } from '../shared/helpers/body-areas.helper';
import {
	CreateMaterialWithClickableHitboxes,
	FemaleHitboxesDict,
	FemaleHitboxesList,
	GetCenterPoint,
	GetAllHitboxes,
	GetMostSpecificBodyAreaFromHitboxes,
	GetShortestBoundingBoxSide,
	MaleHitboxesDict,
	MaleHitboxesList,
	GetHitboxesFromAreaList
} from './biff.hitboxes';
import { MatButtonToggleChange, MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

const maintainCameraState = false;

// light color for 4000 to 5000 lumens makes it looks like it's in a doctor's office
const ambientLightColor = '#ffffff';
const defaultSkinColor = '#f4c99e';
const skyColor = '#ccd7db';
const defaultGreyoutColor = 'black';
const defaultProblemColor = 'red';
const minCameraDistance = 0.25;
const maxCameraDistance = 8;
const cameraZoom = 3; // Everything gets distored at default zoom 1 if you get close to the model

const forwardVector = new THREE.Vector3(0, 0, 1);
const startingCameraTarget = new THREE.Vector3(0, 0.9, 0);

function createCircleTexture(color: string): THREE.CanvasTexture {
	const radius = 100;
	const canvas = document.createElement('canvas');
	const size = radius * 2;
	canvas.width = size;
	canvas.height = size;

	const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
	ctx.beginPath();
	ctx.arc(radius, radius, radius, 0, 2 * Math.PI);
	ctx.fillStyle = color;
	ctx.fill();

	// Create a texture from the canvas
	return new THREE.CanvasTexture(canvas);
}

export interface DecalData {
	point: THREE.Vector3;
	orientation: THREE.Euler;
	scale: number;
	bodyArea: BodyAreaType;
	selected: boolean;
}

@Component({
	selector: 'biff',
	standalone: true,
	imports: [MatButtonToggleModule, MatButtonModule, MatProgressSpinnerModule],
	templateUrl: './biff.component.html',
	styleUrl: './biff.component.scss'
})
export class BiffComponent implements AfterViewInit {
	onModeToggle(event: MatButtonToggleChange) {
		this.mouseWheelBehaviorMode.set(event.value);
	}

	@ViewChild('canvas') canvasRef!: ElementRef<HTMLCanvasElement>;

	private tweensGroup: TWEEN.Group = new TWEEN.Group();

	private scene!: THREE.Scene;
	private camera!: THREE.PerspectiveCamera;
	private renderer!: THREE.WebGLRenderer;
	private controls!: OrbitControls;
	private visiblePerson!: THREE.Mesh;
	private femaleMesh!: THREE.Mesh;
	private maleMesh!: THREE.Mesh;
	private plane!: THREE.Mesh;
	private directionalLight!: THREE.DirectionalLight;
	private skinMaterial!: THREE.MeshStandardMaterial;
	private decalMeshes: THREE.Mesh[] = [];
	private selectedDecalMaterial!: THREE.MeshPhongMaterial;
	private unselectedDecalMaterial!: THREE.MeshPhongMaterial;

	public modelsHaveFinishedLoading = false;

	public gender = input<GenderEnum.Male | GenderEnum.Female>(GenderEnum.Male);
	public skinColor = input<string>(defaultSkinColor);
	public problemColor = input<string>(defaultProblemColor);
	public greyoutColor = input<string>(defaultGreyoutColor);
	public clickableBodyAreas = input<BodyAreaType[] | null>([]);
	public disabled = input<boolean>(false);
	public decalData = input<DecalData[]>([]);

	public selectedDecalDataChanged = output<DecalData>();

	public mouseWheelBehaviorMode = signal<'zoom' | 'resize'>('zoom');

	constructor(
		private _appLoaderService: AppLoaderService,
		private cdRef: ChangeDetectorRef
	) {
		effect(() => {
			const isLoading = this._appLoaderService.isProcessing();
			if (this.modelsHaveFinishedLoading && !isLoading) {
				this.maleMesh.visible = true;
				this.femaleMesh.visible = true;
				this.plane.visible = true;
			}
		});

		effect(() => {
			const genderState = this.gender();
			if (this.modelsHaveFinishedLoading) {
				if (genderState === GenderEnum.Male && this.visiblePerson !== this.maleMesh) {
					this.scene.remove(this.visiblePerson);
					this.visiblePerson = this.maleMesh;
					this.scene.add(this.visiblePerson);
					FemaleHitboxesList.forEach(x => {
						this.scene.remove(x.mesh);
					});
					MaleHitboxesList.forEach(x => {
						this.scene.add(x.mesh);
					});
				} else if (genderState === GenderEnum.Female && this.visiblePerson !== this.femaleMesh) {
					this.scene.remove(this.visiblePerson);
					this.visiblePerson = this.femaleMesh;
					this.scene.add(this.visiblePerson);
					MaleHitboxesList.forEach(x => {
						this.scene.remove(x.mesh);
					});
					FemaleHitboxesList.forEach(x => {
						this.scene.add(x.mesh);
					});
				}
			}
		});

		effect(() => {
			const gender = this.gender();
			const clickableBodyAreas = this.clickableBodyAreas();
			const greyoutColor = this.greyoutColor();
			const skinColor = this.skinColor();
			if (this.modelsHaveFinishedLoading) {
				if (this.skinMaterial) {
					this.skinMaterial.dispose();
				}
				this.skinMaterial = CreateMaterialWithClickableHitboxes(
					skinColor,
					clickableBodyAreas,
					gender,
					greyoutColor
				);
				if (gender == GenderEnum.Female) {
					this.femaleMesh.material = this.skinMaterial;
				} else {
					this.maleMesh.material = this.skinMaterial;
				}
			}
		});

		effect(() => {
			const problemColor = this.problemColor();
			if (this.modelsHaveFinishedLoading) {
				const texture = createCircleTexture(problemColor);
				this.selectedDecalMaterial.map = texture;
				this.unselectedDecalMaterial.map = texture;
			}
		});

		effect(() => {
			const disabled = this.disabled();
			const mode = this.mouseWheelBehaviorMode();
			if (this.modelsHaveFinishedLoading) {
				requestAnimationFrame(() => {
					this.controls.enableRotate = !disabled;
					this.controls.enableZoom = mode == 'zoom' && !disabled;
				});
			}
		});

		effect(() => {
			const decalData = this.decalData();
			const gender = this.gender();
			if (this.modelsHaveFinishedLoading) {
				this.updateDecalData(decalData, gender);
			}
		});

		effect(() => {
			const clickableBodyAreas = this.clickableBodyAreas();
			if (this.modelsHaveFinishedLoading && (clickableBodyAreas == null || clickableBodyAreas.length == 0)) {
				requestAnimationFrame(() => {
					this.resetZoom();
				});
			}
		});
	}

	private updateDecalData(decalData: DecalData[] | null, gender: GenderEnum.Male | GenderEnum.Female) {
		// I don't think it likes updating between frames
		requestAnimationFrame(() => {
			const previouslySelected: DecalData | undefined = this.decalMeshes
				?.map(x => x.userData['decal'] as DecalData)
				.find(x => x.selected);

			if (this.decalMeshes && this.decalMeshes.length > 0) {
				this.scene.remove(...this.decalMeshes);
				this.decalMeshes.forEach(x => {
					x.geometry.dispose();
				});
			}
			if (decalData && decalData.length > 0) {
				const batchOfMeshes = decalData.map((decalData, index) => {
					return this.createDecalMesh(decalData, index, gender);
				});
				this.scene.add(...batchOfMeshes);
				this.decalMeshes = batchOfMeshes;

				const selectedDecal = find(decalData, (x: DecalData) => x.selected);
				const skipZoom = previouslySelected && previouslySelected?.bodyArea == selectedDecal?.bodyArea;
				if (!skipZoom) {
					this.zoomToBodyPart(selectedDecal?.bodyArea ?? null, gender);
				}
			}
		});
	}

	private getBodyPartZoomFactor(bodyAreaType: BodyAreaType) {
		const fillFactor = 0.9 / this.camera.zoom; // Factor to control how much of the screen the object should occupy
		const hitboxDict = this.gender() == GenderEnum.Female ? FemaleHitboxesDict : MaleHitboxesDict;

		const hitbox = has(hitboxDict, bodyAreaType)
			? hitboxDict[bodyAreaType]
			: hitboxDict[GetBodyAreaBySophosId(GetBodyAreaByName(bodyAreaType).parendId ?? 1).name];

		// Create a bounding box around the hitbox object
		const box = new THREE.Box3().setFromObject(hitbox.mesh);
		const size = new THREE.Vector3();
		const center = new THREE.Vector3();
		box.getSize(size);
		box.getCenter(center);

		// Calculate the maximum size of the bounding box
		const maxSize = Math.max(size.x, size.y, size.z);

		// Convert camera FOV to radians
		const fov = this.camera.fov * (Math.PI / 180);

		// Calculate the necessary distance to fit `maxSize` in the viewport according to the FOV and fillFactor
		return maxSize / (2 * Math.tan(fov / 2) * fillFactor);
	}

	private zoomToBodyPart(
		selectedBodyAreaType: BodyAreaType | null,
		gender: GenderEnum.Male | GenderEnum.Female
	) {
		const hitboxes = GetAllHitboxes(gender);
		let target = selectedBodyAreaType;
		let zoomHitbox = find(hitboxes, hb => hb.bodyAreaType === target);
		while (!zoomHitbox && target) {
			const bodyArea = GetBodyAreaByName(target);
			target = GetBodyAreaBySophosId(bodyArea?.parendId ?? 1).name;
			zoomHitbox = find(hitboxes, hb => hb.bodyAreaType === target);
		}
		const desiredZoom = this.getBodyPartZoomFactor(target ?? 'Skin');
		const desiredTarget = zoomHitbox ? GetCenterPoint(zoomHitbox.mesh) : startingCameraTarget;

		this.zoomTo(desiredZoom, desiredTarget);
	}

	public zoomTo(targetDistance: number, desiredTarget?: THREE.Vector3 | null) {
		const animationTime = 500;
		const cam = this.camera;
		const controls = this.controls;
		const initialDistance = cam.position.distanceTo(this.controls.target);

		this.tweensGroup.add(
			new TWEEN.Tween({ distance: initialDistance, target: controls.target })
				.to({ distance: targetDistance, target: desiredTarget ?? controls.target }, animationTime)
				.onUpdate(function (object) {
					const direction = new THREE.Vector3().subVectors(cam.position, object.target).normalize();

					cam.position.copy(direction).multiplyScalar(object.distance).add(object.target);
					cam.lookAt(object.target);
					controls.update();
				})
				.start()
		);
	}

	resetZoom() {
		this.zoomToBodyPart(null, this.gender());
	}

	async initThree() {
		// Load assets
		const [female, male] = await Promise.all([
			new OBJLoader().loadAsync('assets/Realistic_Female_Low_Poly.obj'),
			new OBJLoader().loadAsync('assets/Realistic_Male_Low_Poly.obj')
		]);

		const elem = this.canvasRef?.nativeElement as HTMLCanvasElement;
		const container = elem.parentElement as HTMLElement;

		// Set up the renderer
		this.renderer = new THREE.WebGLRenderer({
			canvas: this.canvasRef?.nativeElement,
			alpha: true,
			antialias: true
		});
		this.renderer.localClippingEnabled = true;
		this.renderer.setPixelRatio(window.devicePixelRatio);
		this.renderer.shadowMap.enabled = true;
		this.renderer.setClearColor(0x000000, 0);
		this.renderer.setSize(container.clientWidth, container.clientHeight);

		this.scene = new THREE.Scene();
		this.scene.background = new THREE.Color(skyColor);

		const geometry = new THREE.PlaneGeometry(15, 15);
		const material = new THREE.MeshStandardMaterial({ color: 'white' });
		this.plane = new THREE.Mesh(geometry, material);
		this.plane.receiveShadow = true;
		this.plane.visible = !this._appLoaderService.isProcessing();
		this.plane.rotateX(Math.PI * -0.5);
		this.scene.add(this.plane);

		// Set up the Camera
		// Notes on Orbit Control + Camera Relations:
		//
		// 1) The "Zoom" for the orbit controls only uses camera position
		// actual camera zoom should never change.
		//
		// 2) If you change the camera in code in any way, you need to
		// call, this.controls.update(); to keep the controls in sync
		const cam = new THREE.PerspectiveCamera(45, elem.clientWidth / elem.clientHeight, 0.1, 1000);
		cam.position.set(0, 0.8, 8);
		cam.aspect = elem.clientWidth / elem.clientHeight;
		cam.zoom = cameraZoom;
		cam.updateProjectionMatrix();
		this.camera = cam;

		this.controls = this.setupController();

		this.skinMaterial = CreateMaterialWithClickableHitboxes(
			this.skinColor(),
			this.clickableBodyAreas(),
			this.gender(),
			this.greyoutColor()
		);

		this.selectedDecalMaterial = new THREE.MeshPhongMaterial({
			map: createCircleTexture(this.problemColor()),
			opacity: 0.8,
			specular: 0x444444,
			normalScale: new THREE.Vector2(0.2, 0.2),
			transparent: true,
			depthTest: true,
			depthWrite: false,
			polygonOffset: true,
			polygonOffsetFactor: -4,
			precision: 'highp',
			wireframe: false,
			side: THREE.FrontSide
		});

		this.unselectedDecalMaterial = this.selectedDecalMaterial.clone();
		this.unselectedDecalMaterial.opacity = 0.2;

		// make the male and female models the same height
		// This makes it to where they can share more hitboxes
		const maleScaleFactor = 0.0245;
		const femaleScaleFactor = 0.026;

		const meshes: THREE.Mesh[] = [];
		[
			{ model: male, scale: maleScaleFactor },
			{ model: female, scale: femaleScaleFactor }
		].forEach(item => {
			const { model, scale } = item;
			model.traverse(node => {
				if (node instanceof THREE.Mesh) {
					// This will smooth the mesh
					node.geometry.deleteAttribute('normal');
					node.geometry = BufferGeometryUtils.mergeVertices(node.geometry);
					node.geometry.computeVertexNormals();

					node.castShadow = true;
					node.scale.set(scale, scale, scale);

					node.material = this.skinMaterial;

					meshes.push(node);
					return;
				}
			});
		});

		[this.maleMesh, this.femaleMesh] = meshes;
		if (this._appLoaderService.isProcessing()) {
			this.maleMesh.visible = false;
			this.femaleMesh.visible = false;
		}
		this.visiblePerson = this.gender() === GenderEnum.Male ? this.maleMesh : this.femaleMesh;

		this.scene.add(this.visiblePerson);

		this.setupRoomLighting();
		this.setupHitboxes();
		this.setupEvents();

		this.renderer.setAnimationLoop(() => this.animate());

		this.updateDecalData(this.decalData(), this.gender());

		this.modelsHaveFinishedLoading = true;
	}

	animate() {
		const cam = this.camera;
		this.directionalLight.position.set(cam.position.x + 1, cam.position.y + 2, cam.position.z);

		// Do animations established by tween
		this.tweensGroup.update();

		// update the controls
		this.controls.update();

		// update the renderer
		this.renderer.render(this.scene, cam);
	}

	setupRoomLighting() {
		const ambientLight = new THREE.AmbientLight(ambientLightColor, 0.8);
		this.scene.add(ambientLight);

		this.directionalLight = new THREE.DirectionalLight(0xdddddd, 1.8);
		this.directionalLight.target = this.visiblePerson;
		this.directionalLight.castShadow = true;
		this.directionalLight.position.set(1, 1, 1);
		const shadowBoxSize = 1.4;
		const shadowCam = this.directionalLight.shadow.camera;
		shadowCam.top = shadowBoxSize;
		shadowCam.bottom = -shadowBoxSize;
		shadowCam.left = shadowBoxSize;
		shadowCam.right = -shadowBoxSize;
		// blur the edges of the shadows just a bit
		this.directionalLight.shadow.radius = 2.5;
		// default shadow resolution is 512 / 512
		this.directionalLight.shadow.mapSize.set(1024, 1024);
		this.scene.add(this.directionalLight);
	}

	setupHitboxes() {
		GetAllHitboxes(this.gender()).forEach(hitBoxInfo => {
			this.scene.add(hitBoxInfo.mesh);
		});
	}

	setupController() {
		// Set up the user input controls
		const lockedPolarAngle = Math.PI / 2;
		const controller = new OrbitControls(this.camera, this.renderer.domElement);

		controller.enableDamping = true;
		controller.maxPolarAngle = lockedPolarAngle; // Lock the controls so turning can only happen along the Y axis
		controller.minPolarAngle = lockedPolarAngle;
		controller.maxDistance = maxCameraDistance;
		controller.minDistance = minCameraDistance;
		controller.enablePan = true;
		controller.enableZoom = this.mouseWheelBehaviorMode() == 'zoom';
		controller.enableRotate = !this.disabled();
		controller.target.copy(startingCameraTarget); // Where the camera should now look at

		if (maintainCameraState) {
			const camera = this.camera;

			// Load camera state
			const savedState = localStorage.getItem('cameraState');

			if (savedState) {
				const cameraState = JSON.parse(savedState);

				camera.position.set(cameraState.position.x, cameraState.position.y, cameraState.position.z);

				controller.target.set(cameraState.target.x, cameraState.target.y, cameraState.target.z);

				camera.zoom = cameraState.zoom;
				camera.updateProjectionMatrix();
				controller.update();
			}

			// Hook the save function into the controls' change event
			controller.addEventListener('change', () => {
				const cameraState = {
					position: {
						x: camera.position.x,
						y: camera.position.y,
						z: camera.position.z
					},
					target: {
						x: controller.target.x,
						y: controller.target.y,
						z: controller.target.z
					},
					zoom: camera.zoom
				};

				localStorage.setItem('cameraState', JSON.stringify(cameraState));
			});
		}

		return controller;
	}

	setupEvents() {
		// Resize the canvas whenever the canvas wrapper changes size.
		new ResizeObserver((entries: ResizeObserverEntry[]) => {
			for (const entry of entries) {
				if (entry.contentRect) {
					this.renderer.setSize(entry.contentRect.width, entry.contentRect.height);
					this.camera.aspect = entry.contentRect.width / entry.contentRect.height;
					this.camera.updateProjectionMatrix();
				}
			}
			this.controls.update();
		}).observe(this.canvasRef.nativeElement.parentElement as HTMLElement);

		// Resize the selected area and zoom to meet it
		this.canvasRef.nativeElement.addEventListener('wheel', (e: WheelEvent) => {
			if (this.mouseWheelBehaviorMode() != 'resize' || this.disabled()) {
				return;
			}

			e.preventDefault();

			const decalData = this.decalData();
			const selected = find(decalData, x => x.selected);
			if (selected) {
				const minScale = 0.015;
				const maxScale = 2.0;
				const tickSize = 0.01;
				const currentScale = selected.scale;
				const skipUpdate =
					(e.deltaY < 0 && currentScale == maxScale) || (e.deltaY >= 0 && currentScale == minScale);
				if (skipUpdate) {
					return;
				}
				const clampedScale = clamp(
					e.deltaY < 0 ? currentScale + tickSize : currentScale - tickSize,
					minScale,
					maxScale
				);

				selected.scale = clampedScale;
				this.selectedDecalDataChanged.emit(selected);
			}
		});

		this.canvasRef.nativeElement.addEventListener('click', (e: MouseEvent) => {
			const rect = this.canvasRef.nativeElement.getBoundingClientRect();

			// Calculate mouse position relative to the canvas
			const x = e.clientX - rect.left;
			const y = e.clientY - rect.top;

			// normalize the mouse position
			const normX = (x / rect.width) * 2 - 1;
			const normY = -(y / rect.height) * 2 + 1;
			const normalizedMousePosition = new THREE.Vector2(normX, normY);

			const rayCaster = new THREE.Raycaster();
			rayCaster.setFromCamera(normalizedMousePosition, this.camera);

			// Get intersections of biff as well as all of the hitboxes
			const hitboxes = GetAllHitboxes(this.gender()).map(x => x.mesh);
			const allIntersections = rayCaster.intersectObjects([this.visiblePerson, ...hitboxes]);
			const clickableHitboxIntersections = rayCaster.intersectObjects(
				GetHitboxesFromAreaList(this.gender(), this.clickableBodyAreas() ?? ['Skin'])
			);
			const biffIntersectionIndex = allIntersections.findIndex(i => i.object === this.visiblePerson);

			if (clickableHitboxIntersections.length > 0 && biffIntersectionIndex > -1) {
				const biffIntersection = allIntersections[biffIntersectionIndex];
				const intersectionsUntilBiff = allIntersections.slice(0, biffIntersectionIndex);

				if (intersectionsUntilBiff.length > 0 && biffIntersection.face) {
					const bodyAreaType = GetMostSpecificBodyAreaFromHitboxes(
						intersectionsUntilBiff.map(x => x.object as THREE.Mesh)
					);

					// Calculate the orientation of the decal based on the normal vector of the place
					// that was clicked. I don't understand it either. Just copied it from online.
					const clonedNormal = biffIntersection.face.normal.clone();
					clonedNormal.transformDirection(this.visiblePerson.matrixWorld);
					const quaternion = new THREE.Quaternion().setFromUnitVectors(forwardVector, clonedNormal);
					const orientation = new THREE.Euler().setFromQuaternion(quaternion);

					// Get size of decal based on gender hitbox
					const bodyAreaHitbox =
						this.gender() == GenderEnum.Female ? FemaleHitboxesDict[bodyAreaType] : MaleHitboxesDict[bodyAreaType];
					const scaleFactor = GetShortestBoundingBoxSide(bodyAreaHitbox.mesh) * 0.8;

					this.selectedDecalDataChanged.emit({
						point: biffIntersection.point,
						scale: scaleFactor,
						orientation: orientation,
						bodyArea: bodyAreaType,
						selected: true
					});
				}
			}
		});
	}

	public createDecalMesh = (
		data: DecalData,
		renderOrder: number,
		gender: GenderEnum.Male | GenderEnum.Female
	): THREE.Mesh => {
		const scale = new THREE.Vector3(data.scale, data.scale, data.scale);
		const mesh = gender === GenderEnum.Female ? this.femaleMesh : this.maleMesh;
		const decalGeometry = new DecalGeometry(mesh, data.point, data.orientation, scale);

		const decalMesh = new THREE.Mesh(
			decalGeometry,
			data.selected ? this.selectedDecalMaterial : this.unselectedDecalMaterial
		);
		decalMesh.userData['decal'] = data;
		decalMesh.renderOrder = renderOrder;
		return decalMesh;
	};

	public ngAfterViewInit() {
		requestAnimationFrame(() => {
			this.initThree();
			this.cdRef.detectChanges();
		});
	}
}
