import * as THREE from 'three';
import { Dictionary, has, keyBy } from 'lodash';
import { BodyAreaType, GetBodyAreaByName, GetParent } from '../shared/helpers/body-areas.helper';
import { GenderEnum } from '../generated-models/enums';

export interface HitBoxInfo {
	bodyAreaType: BodyAreaType;
	mesh: THREE.Mesh;
}

// Useful For debugging
const showAllHitboxes = false;
const specificHitboxesToShow: BodyAreaType[] = [];
const redColor = new THREE.Color(1, 0, 0);
//const blueColor = new THREE.Color(0, 0, 1);

export const CreateMaterialWithClickableHitboxes = (
	skinColor: THREE.ColorRepresentation,
	clickableBodyTypes: BodyAreaType[] | null,
	gender: GenderEnum.Female | GenderEnum.Male,
	greyoutColor: string
) => {
	const material = new THREE.MeshPhysicalMaterial({
		color: skinColor,
		thickness: 0.01
	});

	if (!clickableBodyTypes || clickableBodyTypes.length == 0) {
		return material;
	}

	const hitboxMeshes: THREE.Mesh[] = GetHitboxesFromAreaList(gender, clickableBodyTypes);
	if (hitboxMeshes.length == 0) {
		return material;
	}

	// Prepare hitbox data: positions, scales, and rotation matrices
	const hitboxPositions = hitboxMeshes.map(x => x.position);
	const hitboxScales = hitboxMeshes.map(x => {
		const geo = x.geometry as THREE.BoxGeometry;
		const size = geo.parameters;
		return new THREE.Vector3(size.width, size.height, size.depth);
	});

	const hitboxRotationMatrices = hitboxMeshes.map(x => {
		const rotationMatrix = new THREE.Matrix4().makeRotationFromEuler(x.rotation);
		const inverseRotationMatrix = new THREE.Matrix4().copy(rotationMatrix).invert();
		return inverseRotationMatrix;
	});

	material.onBeforeCompile = function (shader) {
		shader.uniforms['hitboxPositions'] = { value: hitboxPositions };
		shader.uniforms['hitboxScales'] = { value: hitboxScales };
		shader.uniforms['hitboxRotationMatrices'] = { value: hitboxRotationMatrices };
		shader.uniforms['disabledColor'] = { value: new THREE.Color(greyoutColor) };
		//shader.uniforms['decal'] = { value: new THREE.Vector3(0.1, 1.2, 0) };
		//shader.uniforms['decalColor'] = { value: new THREE.Color('blue') };

		shader.vertexShader = `
				varying vec3 vWorldPosition;
				${shader.vertexShader}
			`.replace(
			`#include <begin_vertex>`,
			`#include <begin_vertex>
				vWorldPosition = (modelMatrix * vec4(position, 1.0)).xyz;
			`
		);

		shader.fragmentShader = `
		uniform vec3 hitboxPositions[${hitboxMeshes.length}];
		uniform vec3 hitboxScales[${hitboxMeshes.length}];
		uniform mat4 hitboxRotationMatrices[${hitboxMeshes.length}];
		uniform vec3 disabledColor;
		varying vec3 vWorldPosition;
	
		
		//uniform vec3 decal;
		//uniform vec3 decalColor;
		

		float roundedBoxSDF(vec3 localPosition, vec3 hitboxScale, float radius) {
			vec3 halfScale = hitboxScale * 0.5;
			vec3 q = abs(localPosition) - halfScale + vec3(radius);
			return length(max(q, 0.0)) + min(max(q.x, max(q.y, q.z)), 0.0) - radius;
		}

		//float sphereSDF(vec3 pixelPosition, vec3 sphereCenter, float radius) {
		//	return length(pixelPosition - sphereCenter) - radius;
		//}
	
		${shader.fragmentShader}
	`.replace(
			`#include <dithering_fragment>`,
			`
		bool pixelInEnabledBox = false;
		float minDistance = 10000.0; // Large value to track closest hitbox
		for (int i = 0; i < ${hitboxMeshes.length}; i++) {
			vec3 hitboxPosition = hitboxPositions[i];
			vec3 hitboxScale = hitboxScales[i];
			mat4 hitboxRotationMatrix = hitboxRotationMatrices[i];
			vec3 translatedPosition = vWorldPosition - hitboxPosition;
			vec3 localPosition = (hitboxRotationMatrix * vec4(translatedPosition, 1.0)).xyz;
	
			// Compute distance to the rounded box
			float dist = roundedBoxSDF(localPosition, hitboxScale, 0.01);
			minDistance = min(minDistance, dist);
			
			// Check if the point is within the rounded hitbox
			if (dist < 0.0) {
				pixelInEnabledBox = true;
				break;
			}
		}
		
		// Use smoothstep to blend the color near the edges
		if (!pixelInEnabledBox) {
			float edgeFactor = smoothstep(0.0, 0.001, minDistance);
			gl_FragColor.rgb = mix(gl_FragColor.rgb, disabledColor, edgeFactor * 0.25);
		}

		//float radius = 0.2;
		//float dist = sphereSDF(vWorldPosition, decal, radius);
		//if(dist < 0.0) {
		//	gl_FragColor.rgb =  mix(gl_FragColor.rgb, decalColor, 1.0);
		//}

		#include <dithering_fragment>
	`
		);
	};

	return material;
};

const HitboxMaterial = new THREE.MeshStandardMaterial({
	color: redColor,
	wireframe: true
});

/*
const HitboxRecentlyClickedMaterial = new THREE.MeshStandardMaterial({
	color: blueColor,
	wireframe: true
});
*/

const hitboxMaterialHidden = new THREE.MeshStandardMaterial({
	color: redColor,
	wireframe: true,
	visible: showAllHitboxes
});

const bodyAreaUDKey = 'bodyArea';

const degreesToRads = (degrees: number): number => {
	return degrees * 0.017453292519943295;
};

const createHitbox = (
	bodyAreaType: BodyAreaType,
	size: THREE.Vector3,
	pos: THREE.Vector3,
	xRotationDegrees?: number,
	yRotationDegrees?: number,
	zRotationDegrees?: number
): HitBoxInfo => {
	const geometry = new THREE.BoxGeometry(size.x, size.y, size.z);
	const hitBoxMesh = new THREE.Mesh(
		geometry,
		specificHitboxesToShow.includes(bodyAreaType) ? HitboxMaterial : hitboxMaterialHidden
	);

	// Now we can get the hitbox type
	hitBoxMesh.userData[bodyAreaUDKey] = bodyAreaType;
	hitBoxMesh.position.copy(pos);
	if (xRotationDegrees) {
		hitBoxMesh.rotateX(degreesToRads(xRotationDegrees));
	}
	if (yRotationDegrees) {
		hitBoxMesh.rotateY(degreesToRads(yRotationDegrees));
	}
	if (zRotationDegrees) {
		hitBoxMesh.rotateZ(degreesToRads(zRotationDegrees));
	}

	return {
		bodyAreaType: bodyAreaType,
		mesh: hitBoxMesh
	};
};

const mirrorX = (vec: THREE.Vector3): THREE.Vector3 => {
	const mirrored = vec.clone();
	mirrored.setX(mirrored.x * -1);
	return mirrored;
};

const armSize = new THREE.Vector3(0.2, 0.56, 0.18);
const armZRotation = 15;
const leftArmPos = new THREE.Vector3(0.27, 1.2, -0.05);
const rightArmPos = mirrorX(leftArmPos);

const legSize = new THREE.Vector3(0.22, 0.69, 0.28);
const legXRotation = 5;
const leftLegPos = new THREE.Vector3(0.11, 0.45, -0.033);
const rightLegPos = mirrorX(leftLegPos);

const handSize = new THREE.Vector3(0.1, 0.26, 0.12);
const leftHandPos = new THREE.Vector3(0.37, 0.82, 0.0);
const rightHandPos = mirrorX(leftHandPos);

const fingerZThickness = 0.023;
const fingerSize = new THREE.Vector3(0.053, 0.1, fingerZThickness);
const fingerY = 0.775;
const fingerRelativeX = 0.385;
const indexFingerZPos = 0.0425;

const leftIndexFingerPos = new THREE.Vector3(fingerRelativeX, fingerY, indexFingerZPos);
const leftMiddleFingerPos = new THREE.Vector3(
	fingerRelativeX,
	fingerY,
	indexFingerZPos - fingerZThickness * 1
);
const leftRingFingerPos = new THREE.Vector3(fingerRelativeX, fingerY, indexFingerZPos - fingerZThickness * 2);
const leftPinkyFingerPos = new THREE.Vector3(
	fingerRelativeX,
	fingerY,
	indexFingerZPos - fingerZThickness * 3
);
const rightIndexFingerPos = mirrorX(leftIndexFingerPos);
const rightMiddleFingerPos = mirrorX(leftMiddleFingerPos);
const rightRingFingerPos = mirrorX(leftRingFingerPos);
const rightPinkyFingerPos = mirrorX(leftPinkyFingerPos);

const thumbSize = new THREE.Vector3(0.04, 0.12, 0.06);
const leftThumbPos = new THREE.Vector3(0.34, 0.845, 0.02);
const rightThumbPos = mirrorX(leftThumbPos);

const eyeSize = new THREE.Vector3(0.047, 0.034, 0.055);
const leftEyePos = new THREE.Vector3(0.035, 1.655, 0.1);
const rightEyePos = mirrorX(leftEyePos);

const earSize = new THREE.Vector3(0.04, 0.06, 0.05);
const leftEarPos = new THREE.Vector3(0.08, 1.63, 0.01);
const rightEarPos = mirrorX(leftEarPos);

const footSize = new THREE.Vector3(0.18, 0.25, 0.32);
const leftFootPos = new THREE.Vector3(0.12, 0.0, 0.03);
const rightFootPos = mirrorX(leftFootPos);

const bigToeSize = new THREE.Vector3(0.036, 0.04, 0.11);
const leftBigToePos = new THREE.Vector3(0.12, 0.012, 0.11);
const rightBigToePos = mirrorX(leftBigToePos);

const toeThickness = 0.0162;
const toeSize = new THREE.Vector3(toeThickness, 0.04, 0.09);
const toe2XPos = 0.145;
const toeYPos = 0.012;
const toeZPos = 0.11;
const toeYRotation = 6;
const leftSecondToePos = new THREE.Vector3(toe2XPos, toeYPos, toeZPos);
const leftThirdToePos = new THREE.Vector3(toe2XPos + toeThickness * 1, toeYPos, toeZPos);
const leftFourthToePos = new THREE.Vector3(toe2XPos + toeThickness * 2, toeYPos, toeZPos);
const leftPinkyToePos = new THREE.Vector3(toe2XPos + toeThickness * 3, toeYPos, toeZPos);
const rightSecondToePos = mirrorX(leftSecondToePos);
const rightThirdToePos = mirrorX(leftThirdToePos);
const rightFourthToePos = mirrorX(leftFourthToePos);
const rightPinkyToePos = mirrorX(leftPinkyToePos);

// Note: Some Body Areas do not have hitboxes

// These are because
// - we can't see them yet
//   Tongue
//   Teeth
// - they would connect disjointed children
//   Etremeties
//   Eyes
//   Ears
// - They are too general and would overlap other hitboxes
//   Skin (would overlap everything)
//   Lips (would overlap mouth)

export const MaleHitboxesList: HitBoxInfo[] = [
	createHitbox('Skin', new THREE.Vector3(0.9, 1.8, 0.5), new THREE.Vector3(0, 0.88, 0)),
	createHitbox('Head', new THREE.Vector3(0.2, 0.195, 0.26), new THREE.Vector3(0, 1.661, 0.044), 23),
	createHitbox('Nose', new THREE.Vector3(0.03, 0.065, 0.07), new THREE.Vector3(0, 1.63, 0.12)),
	createHitbox('Mouth', new THREE.Vector3(0.07, 0.027, 0.08), new THREE.Vector3(0, 1.575, 0.1)),
	createHitbox('Neck', new THREE.Vector3(0.2, 0.1, 0.2), new THREE.Vector3(0, 1.52, 0.0), 23),
	createHitbox('Chest', new THREE.Vector3(0.35, 0.25, 0.2), new THREE.Vector3(0, 1.33, 0.05)),
	createHitbox('Abdomen', new THREE.Vector3(0.35, 0.25, 0.3), new THREE.Vector3(0, 1.08, 0.0)),
	createHitbox('Genitourinary', new THREE.Vector3(0.18, 0.15, 0.2), new THREE.Vector3(0, 0.88, 0.025)),

	createHitbox('Left Eye', eyeSize, leftEyePos),
	createHitbox('Left Ear', earSize, leftEarPos),
	createHitbox('Left Arm', armSize, leftArmPos, 0, 0, armZRotation),
	createHitbox('Left Hand', handSize, leftHandPos),
	createHitbox('Left Thumb', thumbSize, leftThumbPos),
	createHitbox('Left Index Finger', fingerSize, leftIndexFingerPos),
	createHitbox('Left Middle Finger', fingerSize, leftMiddleFingerPos),
	createHitbox('Left Ring Finger', fingerSize, leftRingFingerPos),
	createHitbox('Left Pinky Finger', fingerSize, leftPinkyFingerPos),
	createHitbox('Left Leg', legSize, leftLegPos, legXRotation),
	createHitbox('Left Foot', footSize, leftFootPos),
	createHitbox('Left Big Toe', bigToeSize, leftBigToePos, 0, toeYRotation),
	createHitbox('Left Second Toe', toeSize, leftSecondToePos, 0, toeYRotation),
	createHitbox('Left Third Toe', toeSize, leftThirdToePos, 0, toeYRotation),
	createHitbox('Left Fourth Toe', toeSize, leftFourthToePos, 0, toeYRotation),
	createHitbox('Left Pinky Toe', toeSize, leftPinkyToePos, 0, toeYRotation),

	createHitbox('Right Eye', eyeSize, rightEyePos),
	createHitbox('Right Ear', earSize, rightEarPos),
	createHitbox('Right Arm', armSize, rightArmPos, 0, 0, -armZRotation),
	createHitbox('Right Hand', handSize, rightHandPos),
	createHitbox('Right Thumb', thumbSize, rightThumbPos),
	createHitbox('Right Index Finger', fingerSize, rightIndexFingerPos),
	createHitbox('Right Middle Finger', fingerSize, rightMiddleFingerPos),
	createHitbox('Right Ring Finger', fingerSize, rightRingFingerPos),
	createHitbox('Right Pinky Finger', fingerSize, rightPinkyFingerPos),
	createHitbox('Right Leg', legSize, rightLegPos, legXRotation),
	createHitbox('Right Foot', footSize, rightFootPos),
	createHitbox('Right Big Toe', bigToeSize, rightBigToePos),
	createHitbox('Right Second Toe', toeSize, rightSecondToePos, 0, -toeYRotation),
	createHitbox('Right Third Toe', toeSize, rightThirdToePos, 0, -toeYRotation),
	createHitbox('Right Fourth Toe', toeSize, rightFourthToePos, 0, -toeYRotation),
	createHitbox('Right Pinky Toe', toeSize, rightPinkyToePos, 0, -toeYRotation)
];

// Make positional tweaks for the female model
export const FemaleHitboxesList: HitBoxInfo[] = MaleHitboxesList.map(hitBoxItem => {
	const newItem: HitBoxInfo = {
		bodyAreaType: hitBoxItem.bodyAreaType,
		mesh: hitBoxItem.mesh.clone()
	};
	const pos = newItem.mesh.position;
	const x = pos.x;
	const y = pos.y;
	const z = pos.z;
	const bigToeMovement = 0.037;
	const otherToesMovement = 0.035;
	switch (newItem.bodyAreaType) {
		case 'Head':
			pos.setZ(z - 0.02);
			break;
		case 'Left Ear':
		case 'Right Ear':
			pos.setZ(z - 0.025);
			break;
		case 'Left Eye':
		case 'Right Eye':
			pos.setZ(z - 0.01);
			pos.setY(y - 0.01);
			break;
		case 'Left Index Finger':
		case 'Left Middle Finger':
		case 'Left Ring Finger':
		case 'Left Pinky Finger':
		case 'Right Index Finger':
		case 'Right Middle Finger':
		case 'Right Ring Finger':
		case 'Right Pinky Finger':
			pos.setY(y + 0.01);
			pos.setZ(z + 0.005);
			break;
		case 'Left Big Toe':
			pos.setX(x - bigToeMovement);
			break;
		case 'Right Big Toe':
			pos.setX(x + bigToeMovement);
			break;
		case 'Left Leg':
		case 'Right Leg':
			pos.setY(y + 0.02);
			break;
		case 'Left Second Toe':
		case 'Left Third Toe':
		case 'Left Fourth Toe':
		case 'Left Pinky Toe':
			pos.setX(x - otherToesMovement);
			break;
		case 'Right Second Toe':
		case 'Right Third Toe':
		case 'Right Fourth Toe':
		case 'Right Pinky Toe':
			pos.setX(x + otherToesMovement);
	}
	return newItem;
});

export const GetHitboxesFromAreaList = (
	gender: GenderEnum.Male | GenderEnum.Female,
	bodyAreaTypes: BodyAreaType[]
): THREE.Mesh[] => {
	const getHitboxesFromBodyAreaType = (dict: Dictionary<HitBoxInfo>, type: BodyAreaType): THREE.Mesh[] => {
		if (has(dict, type)) {
			return [dict[type].mesh];
		}
		const children = GetBodyAreaByName(type).children;
		if (children && children.length > 0) {
			return children.map(child => getHitboxesFromBodyAreaType(dict, child.name)).flat();
		} else {
			const parent = GetParent(type);
			if (parent && has(dict, parent.name)) {
				return [dict[parent.name].mesh];
			}
		}
		return [];
	};

	const hitboxMeshes: THREE.Mesh[] = [];
	const dict = gender == GenderEnum.Female ? FemaleHitboxesDict : MaleHitboxesDict;

	bodyAreaTypes.forEach(type => {
		getHitboxesFromBodyAreaType(dict, type).forEach(x => {
			hitboxMeshes.push(x);
		});
	});

	return hitboxMeshes;
};

export const GetAllHitboxes = (gender: GenderEnum.Male | GenderEnum.Female): HitBoxInfo[] => {
	if (gender === GenderEnum.Female) {
		return FemaleHitboxesList;
	}
	return MaleHitboxesList;
};

export const MaleHitboxesDict: Dictionary<HitBoxInfo> = keyBy(MaleHitboxesList, 'bodyAreaType');

export const FemaleHitboxesDict: Dictionary<HitBoxInfo> = keyBy(FemaleHitboxesList, 'bodyAreaType');

export const HitboxContainsPoint = (hitbox: THREE.Mesh, point: THREE.Vector3): boolean => {
	const localPoint = point.clone();
	hitbox.worldToLocal(localPoint);

	if (!hitbox.geometry.boundingBox) {
		hitbox.geometry.computeBoundingBox();
	}

	return hitbox.geometry.boundingBox?.containsPoint(localPoint) ?? false;
};

export const GetHitboxBodyArea = (hitbox: THREE.Mesh): BodyAreaType => {
	return hitbox.userData[bodyAreaUDKey] as BodyAreaType;
};

export const GetMostSpecificBodyAreaFromHitboxes = (hitboxes: THREE.Mesh[]): BodyAreaType => {
	let currentMostSpecificBodyArea: BodyAreaType = 'Skin';
	let smallest = Infinity;
	hitboxes.forEach(hitbox => {
		const bbSize = GetBoundingBoxSize(hitbox);
		const area = bbSize.x * bbSize.y * bbSize.z;
		if (area < smallest) {
			currentMostSpecificBodyArea = GetHitboxBodyArea(hitbox);
			smallest = area;
			console.log(currentMostSpecificBodyArea, area, hitboxes);
		}
	});
	return currentMostSpecificBodyArea;
};

export const GetCenterPoint = (mesh: THREE.Mesh): THREE.Vector3 => {
	const middle = new THREE.Vector3();
	if (mesh.geometry) {
		if (!mesh.geometry.boundingBox) {
			mesh.geometry.computeBoundingBox();
		}
		mesh.geometry.boundingBox?.getCenter(middle);
		mesh.localToWorld(middle);
	}
	return middle;
};

export const GetBoundingBoxSize = (mesh: THREE.Mesh): THREE.Vector3 => {
	const geom = mesh.geometry;
	if (!geom.boundingBox) {
		geom.computeBoundingBox();
	}
	const box = geom.boundingBox as THREE.Box3;
	const boxSize = new THREE.Vector3();
	box.getSize(boxSize);
	return boxSize;
};

export const GetShortestBoundingBoxSide = (mesh: THREE.Mesh): number => {
	const boxSize = GetBoundingBoxSize(mesh);
	return Math.min(boxSize.x, boxSize.y, boxSize.z);
};
